/* eslint-disable global-require */
import React from 'react';

const AlbumCard = () => (
  <div className="album-card my-3">
    <h4 className="text-center pb-5 font-weight-bold">
      Some Featured Albums
    </h4>
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-md-3 albumCard">
          <div className="gray-border" />
          <div className="dark-border" />
          <div className="img-div">
            <img
              src={require('../assets/images/r1.png')}
              className="album-img"
              alt="test"
              width="100%"
              height="230"
            />
            <div className="img-content text-center">
              <div className="centered-content">
                <h4 className="text-uppercase font-weight-bold mt-5 mb-0 text-primary">
                  Ikanzu Ye
                </h4>
                <h5 className="text-uppercase font-weight-bold mt-0">R&B</h5>
                <img
                  src={require('../assets/images/logoSmall.png')}
                  className="mt-5"
                  alt="Logo"
                  width="30"
                  height="30"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 albumCard">
          <div className="gray-border" />
          <div className="dark-border" />
          <div className="img-div">
            <img
              src={require('../assets/images/r4.png')}
              className="album-img"
              alt="test"
              width="100%"
              height="230"
            />
            <div className="img-content text-center">
              <div className="centered-content">
                <h4 className="text-uppercase font-weight-bold mt-5 mb-0 text-primary">
                  Aho Urira
                </h4>
                <h5 className="text-uppercase font-weight-bold mt-0">
                  Traditional
                </h5>
                <img
                  src={require('../assets/images/logoSmall.png')}
                  className="mt-5"
                  alt="Logo"
                  width="30"
                  height="30"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 albumCard">
          <div className="gray-border" />
          <div className="dark-border" />
          <div className="img-div">
            <img
              src={require('../assets/images/r2.png')}
              className="album-img"
              alt="test"
              width="100%"
              height="230"
            />
            <div className="img-content text-center">
              <div className="centered-content">
                <h4 className="text-uppercase font-weight-bold mt-5 mb-0 text-primary">
                  Jyaheza
                </h4>
                <h5 className="text-uppercase font-weight-bold mt-0">Gospel</h5>
                <img
                  src={require('../assets/images/logoSmall.png')}
                  className="mt-5"
                  alt="Logo"
                  width="30"
                  height="30"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 albumCard">
          <div className="gray-border" />
          <div className="dark-border" />
          <div className="img-div">
            <img
              src={require('../assets/images/r3.png')}
              className="album-img"
              alt="test"
              width="100%"
              height="230"
            />
            <div className="img-content text-center">
              <div className="centered-content">
                <h4 className="text-uppercase font-weight-bold mt-5 mb-0 text-primary">
                  Ibihe B!7
                </h4>
                <h5 className="text-uppercase font-weight-bold mt-0">Trap</h5>
                <img
                  src={require('../assets/images/logoSmall.png')}
                  className="mt-5"
                  alt="Logo"
                  width="30"
                  height="30"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AlbumCard;
