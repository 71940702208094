/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable global-require */
import React from 'react';
import { Link } from 'react-router-dom';
import Mockup from '../components/Mockup';
import AlbumCard from '../components/AlbumCard';
// import CardsCarousel from '../components/CardsCarousel';
import Footer from '../components/Footer';

export default function Home() {
  return (
    <div>
      <div className="hero pt-2">
        <nav className="myNav navbar navbar-expand-lg navbar-dark bg-dark">
          <Link className="navbar-brand" to="/">
            <img src={require('../assets/images/logo.png')} className="w-75 h-75" alt="" srcSet="" />
          </Link>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <a className="nav-link" href="#download">
                Download
                <span className="sr-only">(current)</span>
              </a>
            </li>
          </ul>
        </nav>

        <div className="mr-auto m-auto row align-items-center pt-5 pt-lg-0 text-light">
          <div className="container-fluid col-lg-4 col-sm-12 px-5 text-center">
            <h1>
              MUSIC FOR
              <br />
              {' '}
              EVERYONE
            </h1>
            <p>Hundreds Of Rwandan Artists</p>
            <div className="mt-5">
              <button type="button" className="download-btn btn">Get It Now !</button>
              <p className="pt-3">
                Only
                {' '}
                <span className="text-primary">Rwf 1000</span>
                {' '}
                Per Month
              </p>
            </div>
          </div>
          <div className="hero-bg col-lg-8 col-sm-12 text-center align-self-end" />
        </div>
      </div>

      <div>
        {/* <CardsCarousel /> */}
      </div>

      <div>
        <Mockup />
      </div>

      <div className="my-5">
        <AlbumCard />
      </div>
      <div className="bg-secondary">
        <Footer />
      </div>
    </div>
  );
}
