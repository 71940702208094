/* eslint-disable react/style-prop-object */
/* eslint-disable global-require */
import React from 'react';
import Carousel from 'nuka-carousel';
import Card from './Card';

export default function Mockup() {
  return (
    <div>

      <div className="container-fluid mockup-section">
        <div className="row py-lg-4">
          <div className="col-12 text-center text-dark pt-lg-5 mt-lg-4">
            <h1>It’s as easy as it Sounds</h1>
            <p>
              Stream over 10,000 songs on our cross-platform mobile apps.
              <br />
              Discover, Save and Share Music on the Go.
            </p>
          </div>
        </div>
        <div className="row px-3 align-items-end">
          <div className="col-lg-6 col-sm-12 mock-one px-4">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-sm-12 pb-3">
                <Carousel
                  autoplay
                  autoplayInterval={5000}
                  // autoplayReverse={true}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button type="button" className="btn btn-primary btn-sm" onClick={previousSlide}>
                      <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M15 18l-6-6 6-6"
                          stroke="#fff"
                          strokeWidth={4}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button type="button" className="btn btn-primary btn-sm" onClick={nextSlide}>
                      <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M9 18l6-6-6-6"
                          stroke="#fff"
                          strokeWidth={4}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  )}
                >
                  <img alt="caritem" src={require('../assets/images/c1.png')} />
                  <img alt="caritem" src={require('../assets/images/c2.png')} />
                  <img alt="caritem" src={require('../assets/images/c3.png')} />
                </Carousel>
              </div>
            </div>

            <div id="download" className="row pb-3">
              <div className="col d-flex justify-content-center">
                <Card type="android" style="bg-secondary" />
              </div>
              <div className="col d-flex justify-content-center">
                <Card type="apple" style="bg-secondary" />
              </div>
            </div>

          </div>
          <div className="col-lg-6 col-sm-12 mock-two" />
        </div>
      </div>
    </div>
  );
}
