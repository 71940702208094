/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable global-require */
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

export default function Terms() {
  return (
    <div>
      <div className="">
        {window.screen.width < 500 ? null : (
          <nav className="myNav navbar navbar-expand-lg navbar-light mb-5">
            <Link className="navbar-brand" to="/">
              <img src={require('../assets/images/logo.png')} className="w-75 h-75" alt="" srcSet="" />
            </Link>

            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/">
                  Download
                  <span className="sr-only">(current)</span>
                </Link>
              </li>
            </ul>
          </nav>
        )}

        <div className="py-lg-5 py-2 container-fluid">
          <div className="row justify-content-center px-2">
            <div className="col-lg-10 col-sm-12 shadow rounded-lg py-5 px-lg-5 px-3">
              <h2 className="text-center">INGOMA</h2>
              <h4 className="text-center text-muted">Terms and Conditions</h4>
              <hr className="py-3" />
              <p className="policy-p">These terms and conditions outline the rules and regulations for the use of Ingoma, the app and Ingoma&apos;s website, located at https://www.ingoma.app.</p>

              <p className="policy-p">
                By accessing this website or the app we assume you accept these terms and conditions.&nbsp;
                Do not continue to use Ingoma if you do not agree to take all of the terms and conditions stated on this page.&nbsp;
              </p>

              <p className="policy-p">
                The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements:&nbsp;
                &quot;Client&quot;, &quot;You&quot; and &quot;Your&quot; refers to you, the person on this website and compliant to the Company’s terms and conditions.&nbsp;
                &quot;The Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;, refers to our Company.&nbsp;
                &quot;Party&quot;, &quot;Parties&quot;, or &quot;Us&quot;, refers to both the Client and ourselves.&nbsp;
                All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client&nbsp;
                in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services,&nbsp;
                in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular,&nbsp;
                plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
              </p>

              <h2>License</h2>

              <p className="policy-p">
                Unless otherwise stated, Ingoma owns the intellectual property rights for Ingoma&apos;s website and app.&nbsp;
                All intellectual property rights are reserved.&nbsp;
                You may view and/or print pages from www.ingoma.app for your own personal use subject to restrictions set in these terms of service.&nbsp;
                The intellectual property of the content on the app is owned by our partners.
              </p>
              <p className="policy-p">You must not:</p>
              <ul>
                <li>Republish material from Ingoma</li>
                <li>Sell, rent or sub-license material from Ingoma</li>
                <li>Reproduce, duplicate or copy material from Ingoma</li>
                <li>Redistribute content from Ingoma</li>
              </ul>

              <h2>Hyperlinking to our Content</h2>

              <p className="policy-p">The following organizations may link to our Website without prior written approval:</p>

              <ul>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                <li>
                  System wide Accredited Businesses except soliciting non-profit organizations,&nbsp;
                  charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
                </li>
              </ul>

              <p className="policy-p">
                These organizations may link to our home page, to publications or to other Website information so long as the link&nbsp;
                (a) is not in any way deceptive;&nbsp;
                (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and&nbsp;
                (c) fits within the context of the linking party’s site.
              </p>

              <p className="policy-p">We may consider and approve other link requests from the following types of organizations:</p>

              <ul>
                <li>commonly-known consumer and/or business information sources;</li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                <li>educational institutions and trade associations.</li>
              </ul>

              <p className="policy-p">
                We will approve link requests from these organizations if we decide that:&nbsp;
                (a) the link would not make us look unfavorably to ourselves or to our accredited businesses;&nbsp;
                (b) the organization does not have any negative records with us;&nbsp;
                (c) the benefit to us from the visibility of the hyperlink compensates the absence of Ingoma; and&nbsp;
                (d) the link is in the context of general resource information.
              </p>

              <p className="policy-p">
                If you are one of the organizations listed in the paragraphs 2 above and are interested in linking to our website,&nbsp;
                you must inform us by sending an e-mail to&nbsp;
                <a href="mailto:support@ingoma.app" className="policy-p">support@ingoma.app</a>
                . Please include your name, your organization name,&nbsp;
                contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website,&nbsp;
                and a list of the URLs on our site to which you would like to link.
              </p>

              <p className="policy-p">Approved organizations may hyperlink to our Website as follows:</p>

              <ul>
                <li>By use of our corporate name; or</li>
                <li>By use of the uniform resource locator being linked to; or</li>
                <li>
                  By use of any other description of our Website&nbsp;
                  being linked to that makes sense within the context and format of content on the linking party’s site.
                </li>
              </ul>

              <p className="policy-p">No use of Ingoma&apos;s logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

              <h2>Content Liability</h2>

              <p className="policy-p">
                We shall not be hold responsible for any content that appears on your Website.&nbsp;
                You agree to protect and defend us against all claims that is rising on your Website.&nbsp;
                No content should appear on any Website that may be interpreted as libelous, obscene or criminal,&nbsp;
                or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights&nbsp;
                as the content on the app is uploaded by Ingoma&apos;s partners (Music Distrbutors and Record Labels).
              </p>

              <h2>Your Privacy</h2>

              <p className="policy-p">
                Please read our Privacy Policy&nbsp;
                <a href="/privacy-policy" target="_blank" rel="noreferrer" className="policy-p">here</a>
              </p>

              <h2>Disclaimer</h2>

              <p className="policy-p">
                To the maximum extent permitted by applicable law, we exclude all representations,&nbsp;
                warranties and conditions relating to our app or website with their use.&nbsp;
                Nothing in this disclaimer will:
              </p>

              <ul>
                <li>Limit or exclude our or your liability for death or personal injury;</li>
                <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li>Limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                <li>Exclude any of our or your liabilities that may not be excluded under applicable law.</li>
              </ul>

              <p className="policy-p">
                The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer:&nbsp;
                (a) are subject to the preceding paragraph; and&nbsp;
                (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
              </p>
            </div>
          </div>

        </div>
        <Footer />
      </div>
    </div>
  );
}
