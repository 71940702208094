/* eslint-disable global-require */
import React from 'react';
import $ from 'jquery';
// import { Link } from 'react-router-dom';

export default function Dcard({ type, style }) {
  $(document).ready(() => {
    $('[data-toggle="tooltip"]').tooltip();
  });
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      style={{ fontSize: '1rem', cursor: 'pointer', opacity: 0.8 }}
      data-toggle="tooltip"
      data-placement="top"
      title="Coming Soon"
      // href={type === 'android' ? 'https://play.google.com/store/apps/details?id=com.audiomack' : 'https://apps.apple.com/us/app/audiomack/id921765888'}
    >
      <div className={`dcard row px-1 py-2 text-white my-2 mx-2 ${style}`}>
        <div className="col-4">
          {type === 'android' ? (
            <img
              className="dcard-img"
              alt="dcard-img"
              src={require('../assets/images/play.png')}
            />
          ) : (
            <img
              className="dcard-img"
              alt="dcard-img"
              src={require('../assets/images/store.png')}
            />
          )}
        </div>
        <div className="col-8">
          {type === 'android' ? (
            <>
              <span>Get it on</span>
              <h4 className="font-weight-bold">Play Store</h4>
            </>
          ) : (
            <>
              <span>Download on the</span>
              <h4 className="font-weight-bold">App Store</h4>
            </>
          )}
        </div>
      </div>
    </a>
  );
}
