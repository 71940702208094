import React from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect
} from 'react-router-dom';
import Home from './Home';
import Terms from './Terms';
import Privacy from './Privacy';
import Share from './Share';

export default () => (
  <>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Redirect from="/download" to="/#download" />
        <Route exact path="/terms-and-conditions" component={Terms} />
        <Route exact path="/privacy-policy" component={Privacy} />
        <Route path="/open/set/:id" component={Share} />
        <Route path="/open/artist/:id" component={Share} />
        <Route
          exact
          path="/feedback"
          render={() => {
            window.location = 'https://forms.gle/wEzjCz5LEkBFuz9t9';
            return null;
          }}
        />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  </>
);
