/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable global-require */
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

export default function Terms() {
  return (
    <div>
      <div className="">
        {window.screen.width < 500 ? null : (
          <nav className="myNav navbar navbar-expand-lg navbar-light mb-5">
            <Link className="navbar-brand" to="/">
              <img src={require('../assets/images/logo.png')} className="w-75 h-75" alt="" srcSet="" />
            </Link>

            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link" to="/">
                  Download
                  <span className="sr-only">(current)</span>
                </Link>
              </li>
            </ul>
          </nav>
        )}

        <div className="py-lg-5 py-2 container-fluid">
          <div className="row justify-content-center px-2">
            <div className="col-lg-10 col-sm-12 shadow rounded-lg py-5 px-lg-5 px-3">
              <h2 className="text-center">INGOMA</h2>
              <h4 className="text-center text-muted">Privacy Policy</h4>
              <hr className="py-3" />

              {/* <h1>Welcome to our Privacy Policy</h1> */}
              <h2>Your privacy is critically important to us.</h2>
              <p className="policy-p">Ingoma is located in Kigali, Rwanda.</p>
              <p className="policy-p">
                It is Ingoma&apos;s policy to respect your privacy regarding any information we may collect while operating our website.&nbsp;
                This Privacy Policy applies to Ingoma (hereinafter, &quot;us&quot;, &quot;we&quot;, or &quot;Ingoma&quot;).&nbsp;
                We respect your privacy and are committed to protecting personally identifiable information&nbsp; you may provide us through the app.&nbsp;
                We have adopted this privacy policy (&quot;Privacy Policy&quot;) to explain what information may be collected on our website or our app,&nbsp;
                how we use this information, and under what circumstances we may disclose the information to third parties.&nbsp;
                This Privacy Policy applies only to information we collect through both the website and the app,&nbsp;
                but does not apply to our collection of information from other sources.
              </p>
              <p className="policy-p">
                This Privacy Policy, together with the Terms of service posted on our website,&nbsp;
                set forth the general rules and policies governing your use of our website and our app.&nbsp;
                Depending on your activities when visiting our website or using our app,&nbsp;
                you may be required to agree to additional terms of service.
              </p>
              <br />

              <h2>Website Visitors and App Users</h2>
              <p className="policy-p">
                Like most operators, Ingoma collects non-personally-identifying information of the sort that web browsers, mobile phones,&nbsp;
                and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request.&nbsp;
                Ingoma&apos;s purpose in collecting non-personally identifying information is to better understand how Ingoma&apos;s visitors use its website and app.&nbsp;
                From time to time, Ingoma may release non-personally-identifying information in the aggregate, e.g. by publishing streaming stats on the app.
              </p>
              <br />

              <h2>Gathering of Personally-Identifying Information</h2>
              <p className="policy-p">
                Users of Ingoma choose to interact with Ingoma in ways that require Ingoma to gather personally-identifying information.&nbsp;
                The amount and type of information that Ingoma gathers depends on the nature of the interaction&nbsp;
                For example, we ask users who choose to Sign Up on the app to provide the first and last name, email address, username, and create a password.
              </p>
              <br />

              <h2>Security</h2>
              <p className="policy-p">
                The security of your Personal Information is important to us, but remember that no method of transmission over the Internet,&nbsp;
                or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information,&nbsp;
                we cannot guarantee its absolute security.
              </p>
              <br />

              <h2>Aggregated Statistics</h2>
              <p className="policy-p">
                Ingoma may collect statistics about the behavior of app users. Ingoma may display this information publicly or provide it to others.&nbsp;
                However, Ingoma does not disclose your personally-identifying information.
              </p>
              <br />

              <h2>E-commerce</h2>
              <p className="policy-p">
                Those who engage in transactions with Ingoma – by paying for Ingoma&apos;s subscription, are asked to provide additional information,&nbsp;
                including as necessary the personal and financial information required to process those transactions.&nbsp;
                In each case, Ingoma collects such information only insofar as is necessary or appropriate to fulfill the purpose of the user&apos;s interaction with Ingoma.&nbsp;
                Ingoma does not disclose personally-identifying information. Transactions are processed by Flutterwave, a third-party app whose privacy policy can be found&nbsp;
                <a href="https://flutterwave.com/rw/privacy-policy" target="_blank" rel="noreferrer" className="policy-p">here</a>
                .
              </p>
              <br />

              <h2>Business Transfers</h2>
              <p className="policy-p">
                If Ingoma, or substantially all of its assets, were acquired, or in the unlikely event that Ingoma goes out of business or enters bankruptcy,&nbsp;
                user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur,&nbsp;
                and that any acquirer of Ingoma may continue to use your personal information as set forth in this policy.
              </p>
              <br />

              <h2>Privacy Policy Changes</h2>
              <p className="policy-p">
                Although most changes are likely to be minor, Ingoma may change its Privacy Policy from time to time, and in Ingoma&apos;s sole discretion.&nbsp;
                Ingoma encourages visitors to frequently check this page for any changes to its Privacy Policy.&nbsp;
                Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
