/* eslint-disable no-alert */
/* eslint-disable global-require */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import $ from 'jquery';
import Card from '../components/Card';
import Footer from '../components/Footer';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

export default function Share() {
  const location = useLocation();
  const { id } = useParams();
  const { pathname } = location;
  const [link, setLink] = useState({ type: null, id: null });
  const [fallbackUrl, setFallbackUrl] = useState('google.com');
  const Platform = navigator.appVersion;
  useEffect(() => {
    if (Platform.includes('Android')) setFallbackUrl('play.google.com/store/apps/details?id=com.audiomack');
    if (Platform.includes('iPhone')) setFallbackUrl('apps.apple.com/us/app/audiomack/id921765888');

    if (pathname.includes('set') && id) {
      setLink({ type: 'set', id });
    } else if (pathname.includes('artist') && id) {
      setLink({ type: 'artist', id });
    } else alert('Invalid Share Url');
  }, []);

  useEffect(() => {
    if (link.type && link.id) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
        || (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform))) {
        $('#shareModal').modal('show');
      } else {
        $('#errorModal').modal('show');
      }
    }
  }, [link]);

  return (
    <div className="bg-secondary" style={{ height: '100vh' }}>
      <div className="bg-dark">
        <nav className="myNav navbar navbar-expand-lg navbar-dark bg-dark container">
          <Link className="navbar-brand" to="/">
            <img src={require('../assets/images/logo.png')} className="w-75 h-75" alt="" srcSet="" />
          </Link>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <a className="nav-link" href="#getapp">
                Download
                <span className="sr-only">(current)</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="container">

        <div className="modal fade " id="shareModal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Open Shared Link</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Do You Want To Open in Ingoma ?</p>
              </div>
              <div className="modal-footer">
                <a className="btn btn-primary" href={`intent://${link.type}/${link.id}#Intent;scheme=ingoma;package=com.ingoma;S.browser_fallback_url=http%3A%2F%2F${fallbackUrl}%2F;end`}>Yes, Open</a>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade " id="errorModal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Open Shared Link</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Sharing Is Only Available on Mobile And Tablet Devices</p>
              </div>
              <div className="modal-footer">
                <Link className="btn btn-primary" to="/" onClick={() => $('#errorModal').modal('hide')}>Get Me Home</Link>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-white text-center pt-4 px-2">
        <a href={`ingoma://${link.type}/${link.id}`} className="h2">Open The Link</a>

        <div className="bg-secondary">
          <div
            className="text-white text-center"
          >
            <p className="pt-4">
              If you Don’t have the app installed in your Mobile Device, Please
              Download it on
            </p>

            <div>
              <div id="getapp" className="row pb-3">
                <div className="col d-flex justify-content-center">
                  <Card type="android" style="bg-dark" />
                </div>
                <div className="col d-flex justify-content-center">
                  <Card type="apple" style="bg-dark" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-only">
        <Footer />
      </div>
    </div>
  );
}
