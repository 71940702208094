/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable global-require */
import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <>
      <div className="container-fluid row m-0 px-4 pt-2 mt-4 text-white text-center align-items-center bg-secondary">
        <div className="col-lg-3 col-sm-6 py-3">
          <a href="/"><img src={require('../assets/images/logo.png')} className="img-fluid" alt="" srcSet="" /></a>
        </div>
        <div className="col-lg-3 col-sm-6 py-3">
          <a className="text-white h5 d-block" href="/privacy-policy">Privacy Policy</a>
          <a className="text-white h5 d-block" href="/terms-and-conditions">Terms And Conditions</a>
        </div>
        <div className="col-lg-3 col-sm-6 py-3">
          <a className="text-white h5 d-block" href="/feedback" target="_blank" rel="noreferrer">Submit Feedback</a>
          <Link to="/" className="text-white h5 d-block" data-toggle="modal" data-target="#contactModal">Contact Us</Link>
        </div>
        <div className="col-lg-3 col-sm-6 py-3">
          <a href="https://www.instagram.com/ingoma.app" target="_blank" rel="noreferrer" className="px-3 social-icon">
            <svg width="2em" height="2em" viewBox="0 0 30 30" fill="none">
              <path
                clipRule="evenodd"
                d="M2.5 7.5a5 5 0 015-5h15a5 5 0 015 5v15a5 5 0 01-5 5h-15a5 5 0 01-5-5v-15z"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                clipRule="evenodd"
                d="M20 14.213a5 5 0 11-9.892 1.466A5 5 0 0120 14.213z"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>

          <a href="https://www.instagram.com/ingoma.app" target="_blank" rel="noreferrer" className="px-3 social-icon">
            <svg width="2em" height="2em" viewBox="0 0 30 30" fill="none">
              <path
                clipRule="evenodd"
                d="M28.75 3.75a13.625 13.625 0 01-3.925 1.912A5.6 5.6 0 0015 9.412v1.25A13.325 13.325 0 013.75 5s-5 11.25 6.25 16.25a14.55 14.55 0 01-8.75 2.5c11.25 6.25 25 0 25-14.375a5.627 5.627 0 00-.1-1.038 9.65 9.65 0 002.6-4.587z"
                stroke="#fff"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
        </div>
        <div className="col-lg-12 text-center text-muted">
          <p style={{ fontSize: '1.2rem' }}>
            {' '}
            &copy; Copyright Imperium&nbsp;
            {new Date().getFullYear()}
          </p>
        </div>
      </div>

      <div className="modal fade" id="contactModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content bg-secondary text-white">
            <div className="modal-header">
              <h5 className="modal-title">Contact Us</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h4>Contact Us</h4>
              <div className="row">
                <div className="col">
                  <h6>Email: support@ingoma.app</h6>
                  <h6>Phone: +250 780 477 291</h6>
                  {/* <h6>Office: KG St 985</h6> */}
                </div>
              </div>
              <h4 className="pt-3 pb-2">Leave Us A Message</h4>
              <div className="row justify-content-center">
                <div className="col-sm-12">
                  <form method="POST" action="https://formsubmit.io/send/3ba3f448-d16c-471c-9c38-a89de6bb1edb">
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <input name="Names" type="text" className="form-control formInput" placeholder="Names" required />
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group">
                          <input name="Email" type="email" className="form-control formInput" placeholder="Email" required />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea name="Message" style={{ resize: 'none' }} rows="5" className="form-control formInput" placeholder="Message" required />
                    </div>
                    <input name="_redirect" type="hidden" id="name" value="https://www.ingoma.app/" />
                    <div className="form-group mt-4">
                      <button type="submit" className="btn btn-primary py-1 px-4 rounded-pill">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
